/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;300;400;700&family=Raleway:wght@300;400;500;600;700;800;900&display=swap');

/* Root Variables */
:root {
  --font-roboto: "Roboto Slab", serif;
  --font-raleway: "Raleway", sans-serif;
}

/* Global Styles */
* {
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-roboto);
  background-color: #212426;
}
