.empty {
    width: 100%;
    margin-top: 3rem;
    min-height: 20px; /* Or adjust depending on your layout */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px; /* Or the expected size */
    width: 100%;
  }
  
  .empty h2 {
    font-size: 1.25rem;
    color: #f9d3b4;
    font-family: var(--font-raleway);
  }
  