/* General Footer Styling */
footer {
  color: white;                   /* White text */
  padding: 20px;                  /* Add padding around content */
  font-family: Arial, sans-serif; /* Font family */
  min-height: 60px;               /* Minimum height for footer */
  max-height: 800px;              /* Limits the height of the footer */
  max-width: 1000px;
  width: 100%;                    /* Ensure full-width consistency */
  margin: 0 auto;                 /* Center the footer */
  box-sizing: border-box;         /* Include padding/borders in dimensions */
  overflow-y: auto;               /* Enable vertical scrolling if needed */
}


footer h3 {
  color: #FFD700;                /* Gold color for headings */
  font-size: 1.5rem;             /* Larger font size */
  margin-bottom: 10px;           /* Margin at the bottom */
}

footer h4 {
  color: #FFD700;                /* Gold color for subsections */
  font-size: 1.2rem;             /* Slightly smaller than h3 */
  margin-top: 20px;              /* Space above the heading */
}

/* Paragraph Styling */
footer p {
  margin-bottom: 15px;           /* Space below paragraphs */
  font-size: 1rem;               /* Standard font size */
  text-align: justify;           /* Justify the text for better readability */
  min-height: 20px;              /* Reserve space to avoid layout shifts */
}

/* Link Styling */
footer a {
  color: #FFD700;                /* Links in gold color */
  text-decoration: none;         /* Remove underline */
}

footer a:hover {
  text-decoration: underline;    /* Underline links on hover */
}

/* Horizontal Line Styling */
footer hr {
  border: 2px solid #FFD700;     /* Gold-colored border for the horizontal line */
  margin: 10px 0;                /* Vertical space around the horizontal line */
}

/* Keyword List Styling */
footer p:last-of-type {
  margin-top: 20px;              /* Space above the last paragraph */
  font-size: 0.9rem;             /* Smaller font size for keywords */
  line-height: 1.4;              /* Better line spacing for readability */
  color: #ccc;                   /* Light grey color */
}

/* Navigation Links Styling */
footer > p > a {
  margin-right: 15px;            /* Space between links */
  font-weight: bold;             /* Bold links */
}

/* Copyright Text */
footer > p:last-of-type {
  margin-top: 10px;              /* Space above copyright text */
  font-size: 0.9rem;             /* Smaller font size for copyright */
  color: #888;                   /* Light grey color */
  text-align: center;            /* Center-align the copyright text */
}

/* Responsive Design */
@media (max-width: 768px) {
  footer {
    margin-left: 1%;
    padding: 15px;               /* Adjust padding for smaller screens */
    width: 100%;                 /* Ensure the footer spans the full width */
  }

  footer h3,
  footer h4 {
    font-size: 1.2rem;           /* Smaller font size for headings */
  }

  footer p {
    font-size: 0.9rem;           /* Smaller font size for paragraphs */
  }
}
