.container {
    width: 100%;
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    float: left;
  }

  .container h2 {
    display: block;
    width: 100%;
    text-align: center;
    margin: 0; /* Remove unnecessary margins */
}

  
  .movie {
    width: 310px;
    height: 465px;
    margin: 1.5rem;
  
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    border: none;
  
    /* transition: all 0.4s cubic-bezier(0.175, 0.885, 0, 1); */
    /* box-shadow: 0px 13px 10px -7px rgba(0, 0, 0, 0.1); */
  }
  
  .movie div:nth-of-type(1) {
    position: absolute;
    padding: 16px;
    width: 100%;
    opacity: 0;
    top: 0;
    color: #f9d3b4;
  }
  
  
  
  .movie div:nth-of-type(2) {
    width: 100%;
    height: 100%;
  }
  
  .movie div:nth-of-type(2) img {
    height: 100%;
    width: 100%;
  }
  
  .movie div:nth-of-type(3) {
    z-index: 2;
    background-color: #343739;
    padding: 16px 24px 24px 24px;
  
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }
  
  .movie div:nth-of-type(3) span {
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
    font-weight: 500;
    color: #f0f0f0;
  }
  
  .movie div:nth-of-type(3) h3 {
    margin-top: 5px;
    font-family: "Roboto Slab", serif;
    color: #f9d3b4;
  }
  
  .movie:hover div:nth-of-type(2) {
    height: 100%;
    opacity: 0.3;
  }
  
  .movie:hover div:nth-of-type(3) {
    background-color: transparent;
  }
  
  .movie:hover div:nth-of-type(1) {
    opacity: 1;
  }
  
  img {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9; 
    object-fit: cover;
  }
  