/* Container for Pagination */
.pagination-container {
  display: flex;
  justify-content: space-around; /* Consistent spacing between elements */
  align-items: center;           /* Vertically align content */
  padding: 10px;
  width: 100%;                   /* Full width to ensure stability */
  height: 60px;                  /* Fixed height to prevent layout shifts */
  position: relative;            /* Ensure consistent positioning */
  box-sizing: border-box;        /* Include padding in width/height */
  z-index: 1;                    
}

/* Pagination Buttons */
.pagination-container button {
  width: 100px;                  /* Fixed button width */
  height: 40px;                  /* Fixed button height */
  padding: 8px 16px;             /* Consistent padding */
  font-size: 16px;
  cursor: pointer;
  background-color: #000;        /* Black background */
  color: #fff;                   /* White text */
  border: none;
  border-radius: 4px;            /* Rounded corners */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transitions */
  box-sizing: border-box;        /* Include padding in dimensions */
}

/* Hover State */
.pagination-container button:hover {
  background-color: #444;        /* Darker shade on hover */
}

/* Disabled Button State */
.pagination-container button:disabled {
  opacity: 0.5;                  /* Visual cue for disabled state */
  cursor: not-allowed;           /* Disable pointer interactions */
}

/* Page Indicator */
.pagination-container .page-info {
  font-size: 16px;               /* Standard font size */
  font-weight: bold;
  color: #fff;                   /* White text for visibility */
  min-width: 80px;               /* Reserve space for page info */
  text-align: center;            /* Align text centrally */
}

/* Spacing for Indicator */
.pagination-container span {
  padding-top: 7px;              /* Align text vertically */
  color: #fff;
  font-size: 14px;               /* Slightly smaller for clarity */
}

/* Mobile-Friendly Adjustments */
@media (max-width: 768px) {
  .pagination-container {
    height: 50px;                /* Adjust height for smaller screens */
  }

  .pagination-container button {
    width: 80px;                 /* Smaller button width */
    height: 35px;                /* Adjust button height */
    padding: 6px 12px;           /* Adjust padding for smaller buttons */
    font-size: 14px;             /* Reduce font size */
  }

  .pagination-container .page-info {
    font-size: 14px;             /* Smaller page info */
    min-width: 60px;             /* Adjust reserved space */
  }
}
