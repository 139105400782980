.download-button-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    margin: 20px 0; /*Optional: adds space around the button*/
    text-align: center; /* Center text for mobile view */
  }
  
  .download-button {
    background-color: #4CAF50; /* Green background */
    color: white; /* White text */
    border: none; /* Remove border */
    padding: 10px 20px; /* Padding for button */
    text-align: center; /* Center text in button */
    text-decoration: none; /* Remove underline */
    display: inline-block; /* Display inline */
    font-size: 16px; /* Font size */
    margin: 4px 2px; /* Margin */
    cursor: pointer; /* Pointer cursor on hover */
    border-radius: 5px; /* Rounded corners */
    transition: background-color 0.3s; /* Transition for hover effect */
  }
  
  .download-button:hover {
    background-color: #45a049; /* Darker green on hover */
  }
  
  .download-para{
    color:  #4CAF50;
    font-size: 1.1rem;
  }