/* Base styling for Navbar */
.navbar{
  background-color: #212426;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 1rem 2rem;
  position: relative;
  margin: 0;
  max-width: 100%;
  max-height: fit-content;
}
.navbar-logo{
  width: 300px;
  height: 50px;
  margin-left: 35%;
}

/* Hamburger menu (mobile) */
.hamburger {
  display: none;
  font-size: 2rem;
  color: white;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 50%;
  /* Center vertically */
  transform: translateY(-50%);
  /* Center vertically */
}

/* Navbar buttons (desktop) */
.navbar-buttons {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  /* Align items to the right */
  align-items: center;
  /* Align items vertically */
}

/* Navbar button styling */
.navbar-button {
  background-color: #212426;
  color: #FFD700;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
}


.navbar-button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

/* Responsive styles for mobile */
@media (max-width: 768px) {
  .navbar {
    padding: 1rem;
    /* Adjust padding for smaller screens */
    flex-direction: row;
  }
  

  /* Adjust navbar title for mobile */
  .navbar-logo {
    width: 180px;
    height: 50px;
    /* Align to the left */
    margin-top: -1rem;
    margin-right: 11rem;
    margin-left: 0%;
  }

  /* Display hamburger icon */
  .hamburger {
    display: block;
    /* Show hamburger icon on mobile */
    position: absolute;
    margin-top: -1.25rem;
    /* Ensure it's centered */
  }

  /* Navbar buttons dropdown (mobile) */
  .navbar-buttons {
    position: absolute;
    left: 0;
    right: 0;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    /* Position the dropdown below the navbar */
    background-color: rgba(0, 0, 0, 0.9);
    /* Dark background for dropdown */
    flex-direction: column;
    gap: 0.2rem;
    z-index: 1;
    display: none;

  }

  /* Show the buttons when the menu is toggled */
  .navbar-buttons.open {
    display: flex;
  }

  .navbar-button {
    padding: 1rem;
    width: 100%;
    text-align: center;
  }
}

/* Desktop styles: title and genres are inline */
@media (min-width: 769px) {
  .navbar-buttons {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: flex-end;
  }
}