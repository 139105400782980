/* MovieDetails.css */
.movie-details {
  max-width: 1000px;
  min-height: 400px;
  /* Set the minimum height based on typical content */
  overflow: hidden;
  margin: 0 auto;
  padding: 20px;
  color: #ffffff;
  background-color: #1c1c1c;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}

.movie-poster {
  margin-left: 28%;
  margin-bottom: 3.5%;
  border: 2px solid #ffcc00;
  width: 410px;
  height: 520px;
  border-radius: 10px;
}

h2 {
  margin-top: 10px;
  font-size: 2rem;
  color: #ffcc00;
}

p {
  font-size: 1rem;
  margin: 10px 0;
}

.budget-revenue {
  margin-top: 20px;
  background-color: #2a2a2a;
  padding: 15px;
  border-radius: 5px;
}

.budget-revenue h3 {
  margin-bottom: 10px;
}

.cast {
  margin-top: 20px;
  margin-bottom: 20px;
}

.cast-member {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  transition: transform 0.2s;
  flex-wrap: wrap;
}

.cast-member:hover {
  transform: scale(1.05);
}

.actor-image {
  width: 50px;
  height: 75px;
  border-radius: 5px;
  margin-right: 10px;
  border: 2px solid #ffcc00;
}

.cast-list {
  gap: 5px;
}

.cast-name {
  font-size: 14px;
  font-weight: bold;
}

.cast-character {
  font-size: 12px;
  color: #777;
}

.cast-info {
  margin-top: 8px;
}

.reviews,
.videos {
  margin-top: 10px;
}

.review {
  background-color: #2a2a2a;
  padding: 10px;
  border-radius: 5px;
  margin-top: 2.5%;
  margin-bottom: 10px;
}

.review h4 {
  color: #ffcc00;
}

.review p {
  font-size: 0.9rem;
}

.review h3 {
  font-size: 1.17rem;
}

.videos iframe {
  margin-top: 10px;
  border: none;
  border-radius: 5px;
}

.videos h3 {
  margin-top: 20px;
}

/* Responsive Styling */
@media screen and (max-width: 768px) {
  .movie-details {
    padding: 10px;
    margin: 0;
  }
  .movie-poster{
    margin-left: 5%;
    width: 310px;
    height: 420px;
  }

  .actor-image {
    width: 40px;
    height: 60px;
  }

  .review {
    margin-top: 7%;
  }

  h2 {
    font-size: 1.5rem;
  }

  p {
    font-size: 0.9rem;
  }

  .cast-member {
    align-items: flex-start;
  }

  .cast-member:hover {
    transform: scale(1.02);
  }
}

.faqs {
  margin-top: 20px;
  background-color: #2a2a2a;
  padding: 15px;
  border-radius: 5px;
}

.faqs h3 {
  margin-bottom: 10px;
}

.faq-item {
  margin-bottom: 15px;
  border-bottom: 1px solid #444;
  padding-bottom: 10px;
}

.faq-item h4 {
  cursor: pointer;
  color: #ffcc00;
  transition: color 0.2s;
}

.faq-item h4:hover {
  color: #ffd700;
}

.faq-item p {
  margin: 5px 0 0 0;
}

/* Responsive Styling */
@media screen and (max-width: 768px) {
  .faqs h3 {
    font-size: 1.2rem;
  }

  .faq-item h4 {
    font-size: 1rem;
  }

  .faq-item p {
    font-size: 0.9rem;
  }
}

.movie-details-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
  background-color: #2a2a2a;
  border-radius: 5px;
  overflow: hidden;
}

.movie-details-table th,
.movie-details-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #444;
}

.movie-details-table th {
  background-color: #1c1c1c;
  color: #ffcc00;
}

.movie-details-table td {
  color: #ffffff;
}

/* Responsive Styling */
@media screen and (max-width: 768px) {
  .movie-details-table {
    font-size: 0.9rem;
  }

  .movie-details-table th,
  .movie-details-table td {
    padding: 8px;
  }
}

.star-rating {
  color: gold;
  font-size: 1.5rem;
  margin-top: 10px;
}

.screenshots {
  margin-top: 20px;
}

.screenshot-image {
  margin-bottom: 10px;
  border-radius: 4px;
}

.paraan {
  margin-top: 20px;
  background-color: #2a2a2a;
  padding: 15px;
  border-radius: 5px;
}

p a {
  text-decoration: none;
  color: #ffff;
}

.btn {
  padding: 7px;
  margin: 5px;
  background-color: #00000000;
  color: #FFD700;
  border: 2px solid #FFD700;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}

.button-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .button-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-right: -4%;
  }

  .btn {
    width: 100%;
    padding: 5px 2px;
  }
}

/* Container for the Related Movies section */
.related-movies {
  margin-top: 20px;
  text-align: center;
}

/* Styling for the Related Movies heading */
.related-movies {
  font-size: 1.5rem;
  /* Adjust for suitable heading size */
  margin-bottom: 15px;
  color: #333;
  /* Adjust color if necessary */
}

/* Flexbox layout for the Related Movies list */
.related-movies-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* Center items */
  gap: 10px;
  /* Space between cards */
}

/* General Styling for Related Movie Cards */
.related-movies-list .movie-card {
  width: 140px;
  /* Default width for larger screens */
  height: 220px;
  /* Default height */
  border-radius: 8px;
  /* Rounded corners */
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);  Soft shadow */
  overflow: hidden;
  /* Prevent overflow of content */
  /* transition: transform 0.3s ease;  Smooth hover effect */
  position: relative;
  /* Needed for proper positioning of the image */
}



/* Image inside the Related Movie Card */
.related-movies-list .movie-card img {
  width: 100%;
  /* Full width to ensure it fills the card */
  height: 100%;
  /* Ensure the height of the image fills the card */
  object-fit: cover;
  /* Ensure image covers the area while maintaining aspect ratio */
  position: absolute;
  /* Position the image within the container */
  top: 0;
  /* Align to the top */
  left: 0;
  /* Align to the left */
}

/* Title text inside the Related Movie Card */
.related-movies-list .movie-card h4 {
  font-size: 0.9rem;
  /* Default font size */
  margin: 8px 0;
  /* Space around title */
  color: #444;
  /* Darker color for readability */
  text-align: center;
  /* Center-align the title */
  position: absolute;
  /* Ensure text stays on top of the image */
  bottom: 0;
  /* Position the text at the bottom */
  width: 100%;
  /* Full width for text container */
  padding: 8px 0;
  /* Padding around the title */
}

/* Additional details text inside the Related Movie Card */
.related-movies-list .movie-card p {
  font-size: 0.8rem;
  /* Smaller font for additional details */
  color: #666;
  /* Muted color */
  text-align: center;
  /* Center-align the details */
}

/* Styling for small cards (if applicable) */
.related-movies-list .small-card {
  width: 110px;
  height: 220px;
}

/* Header styling for Related Movies */
.related-header {
  font-size: 1.25rem;
  color: #fff;
}

/* Breadcrumb Link */
.breadcrumb-link {
  color: #fff;
  text-decoration: none;
}

.arrow {
  font-size: 25px;
  color: #FFD700;
}



/* Responsive Styling for Mobile Devices */
@media (max-width: 768px) {
  .related-movies-list .movie-card {
    width: 100px;
    /* Smaller width for mobile */
    height: auto;
    /* Adjust height dynamically */
    border-radius: 6px;
    /* Slightly smaller rounded corners */
  }

  .related-movies-list .movie-card img {
    width: 100%;
    /* Full width for container */
    height: auto;
    /* Maintain aspect ratio */
    object-fit: cover;
    /* Prevent distortion */
  }

  .related-movies-list .movie-card h4 {
    font-size: 0.8rem;
    /* Smaller font size for titles */
    margin: 5px 0;
    /* Reduced spacing */
  }

  .related-movies-list .movie-card p {
    font-size: 0.7rem;
    /* Smaller font for details */
  }

  .related-header {
    font-size: 1rem;
    margin-left: 0;
    margin-top: 8px;
  }
}


.breadcrumb-link {
  color: #FFFF;
  text-decoration: none;
}