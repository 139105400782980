.search {
    width: 71%;
    margin: 2rem 0 0.25rem;
  
    display: flex;
    align-items: center;
    justify-content: center;
  
    padding: 1.5rem 1.75rem;
    border-radius: 3rem;
    background: #1f2123;
    /* -webkit-box-shadow: 5px 5px 7px #1c1d1f, -5px -5px 7px #222527; */
    /* box-shadow: 5px 5px 7px #1c1d1f, -5px -5px 7px #222527; */
  }
  
  .search input {
    flex: 1;
    border: none;
    font-size: 1.5rem;
    font-family: var(--font-raleway);
    font-weight: 500;
    padding-right: 1rem;
  
    outline: none;
    color: #a1a1a1;
    background: #1f2123;
  }
  
  .search img {
    width: 35px;
    height: 35px;
    cursor: pointer;
  }
  